import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Resources from './Resources';

function ResourcesPage() {
  return (
    <div>
      <Resources/>
    </div>
  )
}

export default ResourcesPage;
