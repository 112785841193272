import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import TeamCards from './TeamCards';

function EventPage() {
  return (
    <div>
      <TeamCards />
    </div>
  )
}

export default EventPage;
